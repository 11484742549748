import { Customer } from './customer.model'
import { environment } from '../../environments/environment'
import { User } from '@auth0/auth0-spa-js'
import { FeatureFlagsSettings } from './feature-flags-settings.model'

// eslint-disable-next-line no-shadow
export enum CustomerType {
  direct,
  indirect
}

export class Features {
  internal: boolean
  customerType: CustomerType
  admin: boolean
  adsEstimator: boolean
  crossReferenceModifier: boolean

  hardwareSets: boolean
  customHardwareSets: boolean
  allegionHardwareSets: boolean
  peOrderHardwareSets: boolean

  crossReferences: boolean
  crossReferenceReadOnly: boolean

  canOrder: boolean
  percentDiscount: boolean

  smck: boolean
  configuredProducts: boolean

  proExpress: boolean
  fastTrack: boolean
  adSystems: boolean
  stanley: boolean
  onlyStanley: boolean

  quotes: boolean
  crmQuote: boolean
  emailQuote: boolean
  canSubmitQuotes: boolean
  steelAct: boolean
  baa: boolean
  specCredit: boolean
  doorFramesTakeoffs: boolean

  keying: boolean
  customerProfile: boolean
  shippingAttachements: boolean

  isLatam: boolean
  sliForm: boolean
  askCountry: boolean
  ciForm: boolean
  shippingTerms: boolean
  shippingComments: boolean
  poSplitting: boolean
  paymentToInfo: string
  certifiedElockDealer: boolean

  sharing: boolean
  notifications: boolean
  showPriceChangeMessage: boolean

  hollowMetalStock: boolean
  doorMarks: boolean

  futurePrice: boolean

  includeDoorLabel: boolean

  country: string

  isCanada: boolean
  //  latam will always be direct

  enablePhasedOrdering: boolean
  enableElevation: boolean
  allowADSHanding?: boolean
  enableSteelcraft: boolean
  enableADSCustomerQuoteDiscount?: boolean
  enableElevationMessage?: boolean
  //restricted product authorization
  enableRPA?: boolean
  enableADSRepricing?: boolean
  showHMSurchargeMessages?: boolean
  enableMultiBranch?: boolean
  showTourGuide?: boolean
  orderHistory?: boolean

  constructor(customer: Customer, user: User, settings: FeatureFlagsSettings) {
    this.createFeatureFlags(customer, user, settings)
  }

  createFeatureFlags(customer: Customer, user: User, settings: FeatureFlagsSettings) {
    if (!customer || !user) {
      return
    }
    const isExternal = user ? user['http://allegion.com/claims/user_type' as keyof User] === 'external' : false

    this.country = customer.isCanada ? 'CAN' : 'USA'

    const isLatam = customer.isLatam
    this.isCanada = customer.isCanada

    const engFlags = environment.featureFlags
    this.enableElevationMessage = customer.isDirect && customer.isRepublicEligible && !this.isCanada
    this.enableElevation =
      engFlags.enableElevation &&
      customer.isDirect &&
      customer.isRepublicEligible &&
      !this.isCanada &&
      (engFlags.enableElevationPilotUsers ? this.userHasRole(user, 'ROOPilot') : true)
    this.enablePhasedOrdering = engFlags.enablePhasedOrdering && customer.isDirect && !this.isCanada
    this.showTourGuide = engFlags.showTourGuide

    this.internal = user['http://allegion.com/claims/user_type' as keyof User] === 'internal'
    this.adsEstimator = this.internal && this.userHasRole(user, 'ADSEstimator')

    this.customerType = customer.isDirect ? CustomerType.direct : CustomerType.indirect

    this.admin = this.internal && this.userHasRole(user, 'Admin')
    this.onlyStanley = customer.tradeAgreementItems.length === 1 && customer.isStanleyEligible
    this.hardwareSets = !this.onlyStanley
    this.customHardwareSets = true
    this.allegionHardwareSets = !isLatam && customer.isDirect

    this.peOrderHardwareSets = false

    this.showPriceChangeMessage = engFlags.showPriceChangeMessage
    this.crossReferences = !this.onlyStanley
    this.crossReferenceModifier = this.userHasRole(user, 'CrossReferenceModifier')
    this.crossReferenceReadOnly = !isExternal && !this.crossReferenceModifier
    this.canOrder = customer.canOrder //  Based on blacklist
    this.percentDiscount = !customer.isDirect

    this.configuredProducts = true
    this.smck = !customer.isDirect

    this.proExpress = customer.isDirect && !isLatam && !this.isCanada
    this.fastTrack = this.isCanada && customer.isDirect
    this.stanley = customer.isStanleyEligible && settings.enableStanley
    this.adSystems =
      customer.isADSystemsEligible &&
      (this.internal ? engFlags.enableADSystemsInternal : engFlags.enableADSystemsExternal)
    this.quotes = customer.isDirect && !this.onlyStanley
    this.crmQuote = customer.isDirect
    this.emailQuote = !customer.isDirect //  Indirect
    this.canSubmitQuotes = isExternal
    this.steelAct = !this.isCanada
    this.baa = !this.isCanada
    this.specCredit = !this.isCanada
    this.doorFramesTakeoffs = !this.isCanada
    this.orderHistory = !this.onlyStanley
    this.keying = true
    this.customerProfile = !customer.isDirect
    this.shippingAttachements = customer.isDirect && isLatam

    //  checkout flow
    this.isLatam = isLatam
    this.sliForm = isLatam
    this.askCountry = false //isLatam
    this.ciForm = isLatam
    this.shippingTerms = !customer.isDirect
    this.shippingComments = !customer.isDirect
    this.poSplitting = customer.isDirect
    this.paymentToInfo = customer.isDirect ? 'vendor' : 'remitPaymentTo'
    this.certifiedElockDealer = customer.isCertified

    this.sharing = true
    this.notifications = false

    this.hollowMetalStock = customer.isDirect && !this.isCanada
    this.doorMarks = !this.isCanada

    this.futurePrice = engFlags.futurePrice && this.internal

    this.includeDoorLabel = customer.isDirect
    this.allowADSHanding = engFlags.enableADSystemsHanding

    this.enableSteelcraft = engFlags.enableSteelcraft
    this.enableADSCustomerQuoteDiscount = engFlags.enableADSystemsCustomerQuoteDiscount
    this.enableRPA = engFlags.enableRPA
    this.enableADSRepricing = engFlags.enableADSRepricing
    this.showHMSurchargeMessages = engFlags.showHMSurchargeMessages
    this.enableMultiBranch = engFlags.enableMultiBranch
  }

  userHasRole(user: User, role: string): boolean {
    return (
      user['http://allegion.com/claims/roles' as keyof User] &&
      user['http://allegion.com/claims/roles' as keyof User].includes(role)
    )
  }

  get isIndirect(): boolean {
    return this.customerType === CustomerType.indirect
  }

  get isDirect(): boolean {
    return this.customerType === CustomerType.direct
  }
}
